import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Slide, Snackbar } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { snackBarAlerts } from '../apollo/reactive-variables';
import { useSnackBars } from '../hooks';

const SnackBarProvider = ({ children }) => {
  const alerts = useReactiveVar(snackBarAlerts);
  const { deleteFromAlert } = useSnackBars();
  return (
    <>
      {children}
      {alerts.map((alert) => (
        <Snackbar
          key={alert.id}
          open={alert.open}
          autoHideDuration={5000}
          onClose={() => deleteFromAlert(alert.id)}
          TransitionComponent={(props) => <Slide {...props} direction="left" />}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={() => deleteFromAlert(alert.id)}
            severity={alert.severity || 'info'}
            color={alert.color || 'text'}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

SnackBarProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SnackBarProvider;
